<template>
  <v-card class="mx-auto" max-width="500">
    <v-card-title class="info white--text title justify-center primary">
      Регистрация
    </v-card-title>
    <v-card-text>
      <v-form v-model="dataValid" ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="signupForm.lastname"
              label="Фамилия"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="signupForm.firstname"
              label="Имя"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="signupForm.middlename"
              label="Отчество"
          />
          </v-col>
          <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="formatedDate"
                    label="Дата Рождения"
                    readonly
                    v-on="on"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="signupForm.birthday"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="signupForm.email"
              label="E-mail"
              :rules="[rules.required]"
              persistent-hint
              hint="Вы можете создать новый ящик на @weldassisit.ru"
            >
              <template v-slot:append>
                <v-btn
                  :class="{ info: createEmail }"
                  depressed
                  tile
                  style="margin-top: -4px; margin-left: -4px; height: 32px;"
                  @click="createWeldassisMailbox"
                >
                  @weldassist.ru
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="signupForm.password"
              type="password"
              label="Пароль"
              :rules="[rules.required]"
          />
          </v-col>
          <v-col col="12">
            <v-text-field
              v-model="signupForm.passwordRepeat"
              type="password"
              label="Повторите пароль"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="info" to="/user/account/login">Назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="signup" :disabled="loading">Далее</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
export default {
  data: () => ({
    signupForm: {
      firstname: '',
      lastname: '',
      middlename: '',
      birthday: '',
      email: '',
      password: '',
      passwordRepeat: '',
    },
    createEmail: false,
    dataValid: false,
    rules: rules,
    date: null,
    menu: false,
    loading: false,
    serverError: ''
  }),
  computed: {
    formatedDate() {
      if(this.signupForm.birthday) {
        const [year, month, day] = this.signupForm.birthday.split('-')
        return day + '.' + month + '.' + year
      }
      return null
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    createWeldassisMailbox() {
      if (!this.signupForm.email) {
        alert('Необходимо ввсети хотя бы название почты')
      }
      if (this.createEmail) {
        this.createEmail = false
        this.signupForm.email = ''
        return
      }
      let [account, domain] = this.signupForm.email.split('@')
      let email = account + '@weldassist.ru'
      this.axios.post('postfix/check-email', { email: email }).then(r => {
        if (!r.data.exist) {
          this.signupForm.email = email
          this.createEmail = true
        }
      })
    },
    save(date) {
      this.$refs.menu.save(false)
    },
    signup() {
      this.$axios.post('user/account/signup', {
        ...this.signupForm,
        createEmail: this.createEmail
      }).then(() => this.$router.push('/user/account/login'))
    }
  }
}
</script>
